@import url(./assets/fonts/stylesheet.css);

body {
  margin: 0;
  font-family: "Jost", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafdfb;
  color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --text-color: #000000;
  --contrast-color: #D4171E;
  --text-on-contrast-color: #fff;
  --background-color: #fff;

  --secondary-contrast-color: #D4171E;
  --text-on-secondary-contrast-color: #fff;
  --contrast-color-hover: #D4171E;
}

p, h1, h2, h3, h4, h5, h6, blockquote, span {
  padding: 0;
  margin: 0;
  color: #000000;
}

a {
  text-decoration: none;
  color: #000000;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


button {
  font-family: "Jost", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.main {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin: 60px 0 0;
}

.main__title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    /* identical to box height, or 26px */


    color: #121212;
}

.main__load-more{
    margin: 20px auto 0;
    width: 300px;
    padding: 12px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: var(--contrast-color);
    font-size: 16px;
}
.cart {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;
}

.cart__title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
}

.cart__items {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;

}

.cart__item {
    width: 100%;
    display: grid;
    grid-template-columns: 100px 1fr max-content;
    grid-template-rows: max-content;
    column-gap: 20px;
}

.cart__item-img {
    width: 100px;
    height: 65px;
    object-fit: scale-down;
}

.cart__item-name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
}

.cart__item-controllers {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.cart__item-counters-and-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
}

.cart__item-counters {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.cart__item-price {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    min-width: 150px;
    text-align: right;
}

.cart__item-counter-btn {
    cursor: pointer;
    transition: all 0.2s linear;
}

.cart__item-counter-btn-icon {
    width: 16px;
    height: 16px;

}

.cart__item-counter-btn_inactive {
    opacity: 0.2;
    pointer-events: none;
}

.cart__item-counter-amount {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 150%;
}

.cart__handlers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.cart__edit-handlers {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

.cart__load-more{
    width: fit-content;
    margin: 0 auto;
}

.cart__edit-handler{
    width: fit-content;
}

.cart__item-delete{
    cursor: pointer;
    margin: 0 0 0 auto;
}

.cart__item-delete-icon{
    width: 20px;
    height: 20px;
}
.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


    border-radius: 8px;

    cursor: pointer;
    width: 100%;
    min-height: 44px;
    padding: 13px 16px;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;

    white-space: nowrap;
}

.button_type_active-color {
    background-color: var(--contrast-color);
    color: var(--text-on-contrast-color);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;

}

.button_type_secondary-active-color {
    background-color: var(--secondary-contrast-color);
    color: var(--text-on-secondary-contrast-color);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
}

.button_type_text-color {
    background-color: var(--text-color);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: var(--background-color);
    
}

.button_type_transparent{
    background-color: transparent;
    color: var(--text-color);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    border: 1px solid var(--contrast-color);
}

.button_type_transparent:hover{
    background-color: var(--btn-transparent-hover);
}

.button_type_active-color:hover {
    background-color: var(--contrast-color-hover);
}

.button_type_secondary-active-color:hover {
    background-color: var(--secondary-contrast-color-hover);
}

.button_type_text-color:hover{
    opacity: 0.8;
}

.button__icon {
    width: 20px;
    height: 20px;
    margin: 0 8px 0 0;
}

.button__icon_no-text {
    margin: 0;
}

.button__icon-stroke {}

.button__icon-fill {}

.button_type_active-color .button__icon-stroke {
    stroke: var(--text-on-contrast-color);
}

.button_type_active-color .button__icon-fill {
    fill: var(--text-on-contrast-color);
}


.button_type_secondary-active-color .button__icon-stroke {
    stroke: var(--text-on-secondary-contrast-color);
}

.button_type_secondary-active-color .button__icon-fill {
    fill: var(--text-on-secondary-contrast-color);
}

.button_type_text-color .button__icon-stroke{
    stroke: var(--background-color);
}

.button_type_text-color .button__icon-fill{
    fill: var(--background-color);
}


.button_type_inactive{
    opacity: 0.3;
    pointer-events: none;
}

.button_type_loading{
    opacity: 0.7;
    pointer-events: none;
}

@media (max-width: 750px) {
    .button {
        padding: 10px;
        min-height: unset;
    }

    .button__icon {
        width: 16px;
        height: 16px;

    }
}
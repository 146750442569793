.app__content {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    padding: 24px 32px 120px;
    margin: 0 auto;
}

@media (max-width: 900px) {
    .app__content {
        padding: 24px 12px 120px;
    }
}
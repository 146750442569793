.table {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 100%;

    padding: 32px 0 0;
    box-sizing: border-box;
    border-radius: 16px;
}

.table__title {

    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: var(--text-color);
    margin: 0;
    padding: 0 0 0 32px;
}



.table__table-container {
    width: 100%;
    box-sizing: border-box;
    border-collapse: collapse;
    overflow: auto !important;
    margin: 32px 0 0;
    padding: 0 32px 32px;
    height: 100%;
}

.table__table {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-collapse: collapse;
    overflow: auto !important;
}


.table::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.table::-webkit-scrollbar-track {
    border: none;
    outline: none;
    display: none;
    background-color: transparent !important;
}

.table::-webkit-scrollbar-thumb {
    background-color: var(--text-color);
    outline: none;
    display: none;
    border-radius: 100px;
}



.table__thead-th {
    color: var(--text-color);
    padding: 0 16px 14px 0;
    width: fit-content;
    text-align: left;
    box-sizing: border-box;
    white-space: nowrap;
    vertical-align: text-top;
}

.table__thead-th_referrals-amount {
    white-space: unset;
    max-width: 120px;
}


.table__thead-th_request-number {
    width: fit-content;
}


.table__thead-th:last-of-type {
    padding: 0 0 14px 0;
}



.table__tbody-td {
    /* max-width: 242px; */

    width: fit-content;
    padding: 26px 60px 26px 0;
    box-sizing: border-box;
    white-space: nowrap;
}

.table__tbody-td-link {
    color: var(--contrast-color);
    text-decoration: underline;
    width: fit-content;
}

.table__tbody-td:last-of-type {
    padding: 26px 0;
}

.table__tbody-td_status {
    width: 80px;
}

.table__tbody-td-row {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;

}

.table__tbody-td-row_right {
    width: 100%;
    justify-content: flex-end;
}

.table__nothing-found {
    padding: 0 32px;
    color: var(--text-color);


    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    margin: 24px 0;
}

.table__tbody-td-row-link-value {
    color: var(--text-color);

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    margin: 0 12px 0 0;
    cursor: pointer;
}

.table__tbody-td-row-link-value_right {
    margin: 0;
    text-align: right;
}


.table__tbody-td-row-link-copy {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.table__tbody-td-row-link-copy-fill {
    fill: var(--text-color);
    opacity: 0.6;
}

.table__preloader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
}

.table__thead-th_right {
    text-align: right;
}

.table__column {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.table__first-name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: var(--text-color);
}

.table__last-name {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: var(--text-color);
}

.table__email {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    text-decoration-line: underline;
    max-width: 135px;
    white-space: nowrap;
    color: var(--text-color);
    overflow: hidden;
    text-overflow: ellipsis;
}

.table__phone {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    text-decoration-line: underline;

    white-space: nowrap;
    color: var(--text-color);
    overflow: hidden;
    text-overflow: ellipsis;
}



.table-status_status_new {
    color: #00B9E1;
}

.table-status_status_in_work {
    color: #C260FF;
}

.table-status_status_ready {
    color: #6C95FF;
}

.table-status_status_done {
    color: #02C473;
}

.table-status_status_cancelled {
    color: #FF7070;
}

@media (max-width: 920px) {
    .table__title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 100%;
        padding: 0 12px;
    }

    .table__table-container {
        padding: 0 12px;
    }

    .table {
        padding: 12px 0 0;
    }

    .table__nothing-found {
        padding: 0 12px;
    }
}

@media (max-width: 560px) {


    .refferals-list__title {
        padding: 12px 16px 0;
    }
}
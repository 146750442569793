
.client-data {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.client-data__title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
}

.client-data__infos {
    display: flex;
    flex-direction: column;
    gap: 12px
}

.client-data__info {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.client-data__info-title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */


    color: #939393;
}

.client-data__info-value {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
}

.client-data__info-value_link{
    text-decoration: underline;
}
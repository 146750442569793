.prev-orders {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.prev-orders__title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
}

.prev-orders__preloader {
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prev-orders__card {
    border: 1px solid #DDDDDD;
    border-radius: 24px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;
}

.prev-orders__card-head{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.prev-orders__card-number{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    text-decoration: underline;
}

.prev-orders__card-date-number{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.prev-orders__card-date{
    font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 130%;
}
.order {
    width: 100%;
    display: flex;
    flex-direction: column;
}



.order__back {
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    display: flex;
    flex-direction: row;
    gap: 7px;
}

.order__content {
    display: grid;
    margin: 40px 0 0;
    width: 100%;
    grid-template-columns: 1fr max-content;
    grid-template-rows: max-content;
    column-gap: 60px;
}

.order__head {
    display: flex;
    flex-direction: row;

}

.order__number {
    display: flex;
    flex-direction: column;
    gap: 4px;

}

.order__preloader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 400px;
}

.order__number-value {
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 130%;
}

.order__number-title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    /* identical to box height, or 16px */


    color: #8A8A8C;
}

.order__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 60px;
}

.order__main-info {
    display: flex;
    flex-direction: column;
    position: sticky;
    gap: 24px;
    top: 100px;
}

.order__customer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.order__customer-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
}

.order__customer-infos {
    display: flex;
    flex-direction: column;
    gap: 12px
}

.order__customer-info {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.order__customer-info-title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */


    color: #939393;
}

.order__customer-info-value {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
}

.order__customer-info-value_link{
    text-decoration: underline;
}
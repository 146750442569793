@font-face {
  font-family: "Jost";
  src: url("Jost-Light.eot");
  src: url("Jost-Light.eot?#iefix") format("embedded-opentype"),
    url("Jost-Light.woff2") format("woff2"),
    url("Jost-Light.woff") format("woff"),
    url("Jost-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Jost";
  src: url("Jost-Regular.eot");
  src: url("Jost-Regular.eot?#iefix") format("embedded-opentype"),
    url("Jost-Regular.woff2") format("woff2"),
    url("Jost-Regular.woff") format("woff"),
    url("Jost-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Jost";
  src: url("Jost-Medium.eot");
  src: url("Jost-Medium.eot?#iefix") format("embedded-opentype"),
    url("Jost-Medium.woff2") format("woff2"),
    url("Jost-Medium.woff") format("woff"),
    url("Jost-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Jost";
  src: url("Jost-SemiBold.eot");
  src: url("Jost-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("Jost-SemiBold.woff2") format("woff2"),
    url("Jost-SemiBold.woff") format("woff"),
    url("Jost-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Itim';
  src: url('Itim-Regular.eot');
  src: url('Itim-Regular.eot?#iefix') format('embedded-opentype'),
      url('Itim-Regular.woff2') format('woff2'),
      url('Itim-Regular.woff') format('woff'),
      url('Itim-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal; 
  font-display: swap;
}

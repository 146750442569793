.add-popup {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: visibility 0.3s, opacity 0.3s linear;
}

.add-popup_active {
  visibility: visible;
}


.add-popup__container {
  font-family: Inter, Arial, sans-serif;
  width: 392px;

  background: #FFFFFF;
  z-index: 1001;
  position: relative;
  /* padding-bottom: 70px;
	transform: translateY(70px); */
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.2s linear;
  border: 1px solid var(--contrast-color);
  box-sizing: border-box;
  border-radius: 20px;
}

.add-popup__container_active {
  opacity: 1;
}

.add-popup__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000000;
  opacity: 0;
  z-index: 999;
  transition: opacity 0.4s linear;
}

.add-popup__background_active {
  opacity: 0.3;
  transition: opacity 0.4s linear;
}

.add-popup__close {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: rgba(18, 18, 18, 0);
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  margin: 24px 24px 0 auto;
}

.add-popup__close:hover {
  background: rgba(18, 18, 18, 0.08);
}

.add-popup__text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  /* or 31px */


  color: #000000;
  margin: -32px 56px 0 24px;
}

.add-popup__btns {
  display: flex;
  flex-direction: column;
  margin: 24px 24px 24px 24px;
}

.add-popup__input-container {
  margin: 24px;
  border: 1px solid var(--contrast-color);
  border-radius: 8px;
  padding: 12px;
  box-sizing: border-box;
}

.add-popup__input {
  caret-color: black;
  width: 100%;
  background-color: unset;
  font: unset;
  text-rendering: unset;
  letter-spacing: unset;
  word-spacing: unset;
  text-transform: unset;
  text-indent: unset;
  text-shadow: unset;
  display: unset;
  text-align: unset;
  appearance: unset;
  font-style: normal;
  font-weight: normal;
  padding: 0;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 26px */
  border: none;
  outline: none;
  color: #000000;
  resize: none;
}

.add-popup__btn-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  margin: 0;
}



.add-popup__btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  border-radius: 12px;
  width: 100%;
  transition: all 0.2s linear;
}

.add-popup__btn_agree {
  background: var(--contrast-color);
}

.add-popup__btn-text_agree {
  color: #FFFFFF;
}

.add-popup__btn_disagree {
  border: 1px solid var(--contrast-color);
  margin: 8px 0 0;
}

.add-popup__btn-text_disagree {
  color: var(--contrast-color);
}

.add-popup__btn_disabled {
  opacity: 0.3;
  pointer-events: none;
}

.add-popup__product{
  width: calc(100% - 24px * 2);
  box-sizing: border-box;
  margin: 24px 24px 24px 24px;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: max-content;
  column-gap: 20px;
}

.add-popup__err{
  font-size: 13px;
  color: var(--contrast-color);
}


@media (max-width: 448px) {
  .add-popup__container {
    width: 90%;

  }

  .add-popup__text {
    font-size: 16px;
  }
}
.notes {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.notes__title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
}

.notes__create {
    width: fit-content;
}

.notes__cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;

}

.notes__card {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #F8F8F8;
    border: 1px solid #DDDDDD;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 12px;
}

.notes__card-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

}

.notes__card-controller{
    cursor: pointer;
}

.notes__card-date {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
}

.notes__card-controllers{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.notes__card-text{
    font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 150%;
}

.notes__load-more{
    width: fit-content;
    margin: 0 auto;
}
.status-drop {
    position: relative;
    width: 140px;
}

.status-drop__selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 122%;
}

/* 
new: 'Новый',
in_work: 'В работе',
ready: 'Готов к выдаче',
done: 'Завершен',
cancelled: 'Отменен', */

.status-drop__selected_status_new {
    background: rgba(0, 185, 225, 0.08);
    border: 1px solid #00B9E1;
    color: #00B9E1;
}

.status-drop__selected_status_in_work {
    background: rgba(194, 96, 255, 0.08);
    border: 1px solid #C260FF;
    color: #C260FF;
}

.status-drop__selected_status_ready {
    background: rgba(108, 149, 255, 0.08);
    border: 1px solid #6C95FF;
    color: #6C95FF;
}

.status-drop__selected_status_done {
    background: rgba(2, 195, 115, 0.08);
    border: 1px solid #02C473;
    color: #02C473;
}

.status-drop__selected_status_cancelled {
    background: rgba(255, 112, 112, 0.08);
    border: 1px solid #FF7070;
    color: #FF7070;
}

.status-drop__arrow {
    padding: 8px 4px;
    display: flex;
    align-items: center;
    justify-content: center;

}


.status-drop__selected_status_new .status-drop__arrow {
    background-color: #00B9E1;
}

.status-drop__selected_status_in_work .status-drop__arrow {
    background-color: #C260FF;
}

.status-drop__selected_status_ready .status-drop__arrow {
    background-color: #6C95FF;
}

.status-drop__selected_status_done .status-drop__arrow {
    background-color: #02C473;
}

.status-drop__selected_status_cancelled .status-drop__arrow {
    background-color: #FF7070;
}


.status-drop__arrow-icon {
    width: 20px;
    height: 20px;
    transition: all 0.2s linear;
}

.status-drop__arrow-icon_active {
    transform: rotate(180deg);
}

.status-drop__drop {
    position: absolute;
    top: calc(100% + 12px);
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;
    padding: 12px;
    border-radius: 8px;
    background-color: #F1F1F1;

}

.status-drop__drop-item {
    color: var(--text-color);
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 122%;
}
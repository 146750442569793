.delivery {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.delivery__title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
}

.delivery__form {
    display: flex;
    flex-direction: column;
    width: 408px;

}

.delivery__selectors {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    column-gap: 16px;
}

.delivery__selector {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    color: var(--text-color);
    border: 2px solid #D1D1D1;
    border-radius: 12px;
    transition: all 0.2s linear;
    cursor: pointer;
}

.delivery__selector_selected {
    background: rgba(225, 28, 35, 0.12);
    border: 2px solid #E11C23;
    color: #E11C23;
}

.delivery__delivery-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 40px 0 0;
    gap: 20px;
}

.delivery__privat-house-selector {
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 16px;
}

.delivery__privat-house-chekbox {
    width: 20px;
    height: 20px;
    border: 1px solid #A0A0A0;
    border-radius: 3px;
    transition: all 0.2s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.delivery__privat-house-chekbox_cheked {
    background: #F5EAFD;
    /* primary/500 */

    border: 1px solid var(--contrast-color);
}


.delivery__privat-house-chekbox-tick {
    opacity: 0;
    transition: all 0.2s linear;

}

.delivery__privat-house-chekbox_cheked .delivery__privat-house-chekbox-tick {
    opacity: 1;
}

.delivery__input-container {
    width: 100%;
    border: 1px solid #939393;
    border-radius: 12px;
    padding: 12px 16px;
    box-sizing: border-box;
}

.delivery__input{
    caret-color: black;
    width: 100%;
    background-color: unset;
    font: unset;
    text-rendering: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    appearance: unset;
    font-style: normal;
    font-weight: normal;
    padding: 0;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 26px */
    border: none;
    outline: none;
    color: #000000;
    resize: none;
}

.delivery__inputs{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
}

.delivery__inputs-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.delivery__privat-house-title{
    font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 100%;
}

.delivery__save{
    width: fit-content;
    margin: 20px 0 0;
}